import CounterPage from "./CounterPage";


function Section17() {
    return (
        <div>
            Section 17
            <CounterPage initialCount={0}/>
        </div>
    );
}

export default Section17;