import TablePage from "./TablePage";

import '../Assets/Css/Table.css'

function Section15() {
    return (
        <div>
            <TablePage></TablePage>
        </div>
    );
}

export default Section15;