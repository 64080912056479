import UserList from "./UserList";

function  Section21(){

    return (
        <div className="section21">
         <UserList />
        </div>
    )

}
export default Section21