import TablePage16 from "./TablePage16";


function Section16() {
    return (
        <div>
            Section 16
            <TablePage16></TablePage16>
        </div>
    );
}

export default Section16;